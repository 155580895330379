<template>
  <div class="login-container">
    <div class="login-form">
      <div class="flex-column-start" style="position: relative; z-index: 900">
        <div class="login-title font-bold">
          {{ $trans('用户登录', '用户登录') }}
        </div>
        <div class="login-tip">
          {{ $trans('上殊房欢迎您', '上殊房欢迎您') }}
        </div>
        <div class="login-input-box flex-bet-cen">
          <input
            type="text"
            :placeholder="$trans('手机号码', '手机号码')"
            v-model="phone"
          />
        </div>
        <div class="login-input-box flex-bet-cen">
          <input
            type="text"
            :placeholder="$trans('验证码', '验证码')"
            v-model="picVerifyCode"
          />
          <img
            :src="picUrl"
            alt="图片加载失败,点击重试"
            @click="getPicVerification"
          />
        </div>
        <div class="login-input-box flex-bet-cen">
          <input
            type="text"
            :maxlength="6"
            :placeholder="$trans('验证码', '验证码')"
            v-model="verify"
          />
          <el-button
            type="primary"
            class="font-regular"
            :class="[
              verifyText !== $trans('获取验证码', '获取验证码') ? 'active' : '',
              $i18n.locale == 'DE' ? 'foreignlang-btn' : '',
            ]"
            @click="verifyHandle"
            :loading="isLoading"
            >{{ verifyText }}</el-button
          >
        </div>
        <div class="agree-policy-box" @click="policy = !policy">
          <input type="checkbox" v-model="policy" />
          <span
            >{{ $trans('登录即代表阅读并同意', '登录即代表阅读并同意')
            }}<span
              @click.stop="regAgreementShow = true"
              class="policy font-bold"
              >{{ $trans('用户使用协议', '用户使用协议') }}</span
            >{{ $trans('与', '与')
            }}<span @click.stop="policyShow = true" class="policy font-bold">{{
              $trans('隐私政策', '隐私政策')
            }}</span></span
          >
        </div>
        <div class="btn-fill font-medium" @click="loginDecide">
          {{ $trans('登录', '登录') }}
        </div>
      </div>
    </div>
    <el-dialog
      :title="$trans('上殊房网页用户注册协议', '上殊房网页用户注册协议')"
      :visible.sync="regAgreementShow"
    >
      <div v-for="item in regAgreement" :key="item.id">
        <h3 class="reg-title">{{ item.title }}</h3>
        <div v-for="ite in item.content" :key="ite.id">
          <p class="reg-small-title">{{ ite.text }}</p>
          <p
            v-for="(i, index) in ite.content"
            :key="index"
            class="reg-small-text"
          >
            {{ i }}
          </p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="regAgreementShow = false">
          {{ $trans('确 定', '确 定') }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="$trans('上殊房隐私政策', '上殊房隐私政策')"
      :visible.sync="policyShow"
    >
      <div v-for="item in policyContent" :key="item.id">
        <h3 class="reg-title">{{ item.title }}</h3>
        <div v-for="ite in item.content" :key="ite.id">
          <h3 class="reg-small-title">{{ ite.text }}</h3>
          <p
            v-for="(i, index) in ite.content"
            :key="index"
            class="reg-small-text"
          >
            {{ i }}
          </p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="policyShow = false">{{
          $trans('确 定', '确 定')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPicVerification,
  getNoteVerification,
  login,
} from '@/util/js/api.js'
import { regAgreement, policyContent } from '@/util/resource/static.jsx'
export default {
  name: '',
  data() {
    return {
      policy: false, // 同意用户协议
      phone: '', // 手机号码
      verify: '', // 短信验证码
      verifyText: this.$trans('获取验证码', '获取验证码'), // 短信验证码按钮文字
      // isPostVerify:false,         // 是否处于发送验证码中
      picVerifyCode: '', // 图片验证码
      picCode: '', // 图片验证码 正确值
      picUrl: '', // 图片验证码地址
      isLoading: false, // 按钮加载
      regAgreementShow: false, // 用户注册协议
      policyShow: false, // 隐私政策
    }
  },
  mounted() {
    this.getPicVerification()
  },
  methods: {
    // 登录
    loginDecide() {
      if (!this.policy) {
        this.$confirm("是否同意'用户协议'与'隐私政策'")
          .then(
            () => {
              this.policy = true
              this.loginHandle()
            },
            () => {
              return
            }
          )
          .catch(() => {
            return
          })
      } else {
        this.loginHandle()
      }
    },
    loginHandle() {
      let telReg = /^1[345789][0-9]{9}$/
      let picReg = /^[0-9]{4}$/
      if (telReg.test(this.phone) && picReg.test(this.verify)) {
        login({
          phone: this.phone,
          phoneVerifyCode: this.verify,
        }).then((res) => {
          if (res.code == 200) {
            this.loginHandleMethods.call(
              this,
              { name: 'token', value: res.user.token, day: 1 / 24 },
              { key: 'user', value: res.user }
            )
          } else {
            this.$message.error(res.msg)
            this.getPicVerification()
            this.verify = ''
            this.picVerifyCode = ''
          }
        })
      } else {
        this.$message.warning(this.$trans('请认真填写信息', '请认真填写信息'))
      }
    },
    // 获取图片验证码
    getPicVerification() {
      getPicVerification().then((res) => {
        if (res.code == '200') {
          this.picUrl = res.data.picVerifyCodeUrl
          this.picCode = res.data.picVerifyCode
        } else {
          this.picUrl = ''
          this.picCode = ''
        }
      })
    },
    // 获取短信验证码
    verifyHandle() {
      // 是否正在获取验证码
      if (this.verifyText !== this.$trans('获取验证码', '获取验证码')) {
        return false
      }
      if (this.picCode != this.picVerifyCode.toUpperCase()) {
        this.$message.error(
          this.$trans('请输入正确的验证码', '请输入正确的验证码')
        )
        this.picVerifyCode = ''
        this.getPicVerification()
        return
      }
      let telReg = /^1[345789][0-9]{9}$/
      if (telReg.test(this.phone)) {
        this.isLoading = true
        let params = {
          phone: this.phone,
          verifyCode: this.picVerifyCode.toUpperCase(),
        }
        getNoteVerification(params)
          .then((res) => {
            if (res.code != '200') {
              this.$message.error(res.msg)
            } else {
              this.verifyText = 60
              this.countDown()
            }
            this.isLoading = false
          })
          .catch(() => {
            this.$message.error(
              this.$trans('请求失败,请稍后再试', '请求失败,请稍后再试')
            )
            this.isLoading = false
          })
        return
      } else {
        this.phone = ''
        this.picVerifyCode = ''
        this.getPicVerification()
        this.$message.error(
          this.$trans(
            '请正确填写手机号码和验证码',
            '请正确填写手机号码和验证码'
          )
        )
      }
      return
    },
    // 倒计时
    countDown() {
      if (this.verifyText > 0) {
        this.verifyText--
        setTimeout(this.countDown, 1000)
      } else {
        this.verifyText = this.$trans('获取验证码', '获取验证码')
        return false
      }
    },
  },
  computed: {
    regAgreement() {
      return regAgreement()
    },
    policyContent() {
      return policyContent()
    },
  },
}
</script>
<style scoped>
.login-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('../../assets/img/login/bg.jpg');
  background-size: 100% 100%;
}
.login-form {
  position: absolute;
  width: 500px;
  height: 700px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  background: #ffffff;
  padding: 95px 45px;
}
.login-title {
  font-size: 36px;
  margin-bottom: 25px;
}
.login-tip {
  color: #444444;
  font-size: 18px;
  margin-bottom: 75px;
}
.login-input-box {
  height: 64px;
  padding: 0 16px 0 32px;
  border: 1px solid #111111;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 2px;
}
.login-input-box input {
  flex: 1;
  outline: none;
  border: none;
  margin-right: 20px;
  background: transparent;
}
.login-input-box img {
  height: 33px;
  width: auto;
  cursor: pointer;
  line-height: 33px;
  font-size: 12px;
}
.verify-box {
  height: 33px;
  line-height: 33px;
  background: #444444;
  border-radius: 4px;
  color: #ffffff;
  /* padding: 0 20px; */
  width: 130px;
  text-align: center;
  cursor: pointer;
}
.verify-box.active {
  background: #dddddd;
}
.foreignlang-btn {
  padding: 12px 10px;
}
.agree-policy-box input {
  margin-right: 10px;
}
.agree-policy-box {
  font-size: 14px;
  cursor: pointer;
  color: #444444;
  margin-bottom: 65px;
}
.agree-policy-box .policy {
  color: #111111;
  font-weight: bolder;
}
.btn-fill {
  cursor: pointer;
}
.reg-title,
.reg-small-title,
.reg-small-text {
  line-height: 1.5;
}
.reg-small-text {
  text-indent: 2em;
}
</style>
<style>
.el-button--primary.active {
  background: #dddddd;
  border-color: #dddddd;
}
</style>
